@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,900;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;400;700&display=swap');

@import url('../fonts/nunito/style.css');
@import url('../fonts/metropolis/style.css');

html {
	background-color: #f7f7f7;
}

body {
	margin: 0;
	font-family: 'Roboto', sans-serif;
	background-color: #f7f7f7;

	--color-primary: #ffdf5b;
	--color-link: #3eabb2;
	--color-green: #21ab21;
	--color-error: #e35151;
	--color-background-2: #323840;
	--color-light-gray: #cecece;

	--shadow: 0 0.25rem 1.5rem rgba(0, 0, 0, 0.1);
	--shadow-1: 0 0.2rem 1rem rgba(0, 0, 0, 0.05);

	--margin-xs: 0.5em;
	--margin-s: 1em;
	--margin-m: 2em;
	--margin-l: 4em;
}

* {
	font-family: 'Nunito', sans-serif;
	box-sizing: border-box;
	margin: 0;
}

.nunito {
	font-family: 'Nunito', sans-serif;
}

.metropolis {
	font-family: 'Metropolis', sans-serif;
}

.bold,
b {
	font-weight: 600;
}

.mobile .scale-down-mobile {
	& > * {
		transform: scale(0.5);
	}
}

.card {
	background: white;
	padding: 2rem;
	box-sizing: border-box;
	box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.05);
	border-radius: 0.5rem;

	& > .header {
		display: flex;
		justify-content: flex-end;
		align-items: center;

		form {
			display: flex;
			align-items: center;
		}

		.mobile & {
			flex-direction: column;
			align-items: flex-start;

			.spacer {
				height: 1rem;
			}
		}
	}
}

h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
	font-weight: 500;
}

.h1 {
	font-size: 3rem;
}

.h2 {
	font-size: 2rem;
}

.h3 {
	font-size: 1.5rem;
}

.h4 {
	font-size: 1rem;
}

label,
.label {
	font-size: 0.75em;
	color: rgb(94, 94, 94);
}

input,
textarea,
select {
	font-family: 'Roboto', sans-serif;
	font-size: 0.85em;
	padding: 0.75em 1em;
	border-radius: 0.35em;
	border: solid 1px rgb(199, 199, 199);
	box-sizing: border-box;
	max-width: 100%;

	&.invalid {
		border: solid 1px rgb(255, 161, 161);
		background-color: rgb(255, 245, 245);
	}
}

.badge {
	background: var(--color-error);
	color: white;
	padding: 0.5em 1em;
	border-radius: 1em;
	display: inline-block;
    white-space: nowrap;

	&.gray {
		background-color: #d9d9d9;
	}
	&.green {
		background-color: var(--color-green);
	}
	&.yellow {
		background-color: var(--color-primary);
		color: black;
	}
}

button.nostyle {
	background: transparent;
	border: none;
	color: inherit;
	padding: 0;
	cursor: pointer;

	&:disabled {
		cursor: not-allowed;
		opacity: 0.5;
	}

	&:focus-visible {
		outline: solid 0.2em var(--color-primary);
		outline-offset: 0.2em;
	}
}

.button,
button:not(.nostyle) {
	text-decoration: none;
	font-size: 0.85em;
	padding: 0.75em 1em;
	border-radius: 0.35em;
	border: 1px solid transparent;
	background: var(--color-primary);
	color: black;
	cursor: pointer;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	gap: 0.5em;

	.icon {
		font-size: 1.25em;
		color: currentColor;
	}

	&.delete,
	&.type-remove,
	&.type-delete {
		background: var(--color-error);
		outline-color: var(--color-error) !important;
	}

	&.type-secondary,
	&.secondary {
		background: transparent;
		color: var(--color-primary);

		&.black {
			color: black;
		}
	}

	&.type-outlined,
	&.outlined {
		background: transparent;
		border: 1px solid black;
		color: black;
	}

	&:focus-visible {
		outline: solid 0.2em var(--color-primary);
		outline-offset: 0.2em;
	}

	&:disabled {
		cursor: not-allowed;
		filter: grayscale(100%);
		opacity: 0.5;
	}

	&.loading {
		cursor: not-allowed;
		color: transparent;

		@keyframes rotate {
			0% {
				transform: translateY(-50%) translateX(-50%) rotate(0deg);
			}
			100% {
				transform: translateY(-50%) translateX(-50%) rotate(360deg);
			}
		}

		&:after {
			content: '';
			display: inline-block;
			width: 1em;
			height: 1em;
			border-radius: 50%;
			border: 2px solid var(--color-background-2);
			border-color: var(--color-background-2) var(--color-background-2) var(--color-background-2)
				transparent;
			animation: rotate 1s linear infinite;

			position: absolute;
			left: 50%;
			top: 50%;
		}
	}
}

.row {
	display: flex;

	.column {
		display: flex;
		flex-direction: column;
	}

	&.space-between {
		justify-content: space-between;
	}

	&.center {
		align-items: center;
	}
}

#root {
}

.spacer,
.divider {
	&.xs {
		height: var(--margin-xs);
	}
	&.s {
		height: var(--margin-s);
	}
	&.m {
		height: var(--margin-m);
	}
	&.l {
		height: var(--margin-l);
	}

	&.wxs {
		width: var(--margin-xs);
	}
	&.ws {
		width: var(--margin-s);
	}
	&.wm {
		width: var(--margin-m);
	}
	&.wl {
		width: var(--margin-l);
	}

	&.auto {
		flex-grow: 1;
	}

	&.divider {
		position: relative;

		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 0;
			width: 100%;
			height: 1px;
			background-color: #ccc;
		}
	}
}

.loader {
	font-size: 4em;
	width: 1em;
	height: 1em;
	border-radius: 1em;
	background-image: conic-gradient(transparent, transparent, gray);
	mask: radial-gradient(circle 0.4em, transparent 98%, #fff 100%);
	animation: spin 1.5s linear infinite;

	@keyframes spin {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
}

.tableFixHead {
	overflow: auto;
	height: 300px;
	border: solid 1px #ddd;

	thead th {
		position: sticky;
		top: 0;
		z-index: 1;
	}

	table {
		border-collapse: collapse;
		width: 100%;
	}
	th,
	td {
		padding: 8px 16px;
		text-align: left;
	}
	th {
		background: #eee;
	}

	td {
		border-bottom: solid 1px #ddd;
	}
}

.price-table {
	* {
		font-size: 0.9rem;
	}

	th {
		text-align: left;
		padding: 0.25em 0;
		padding-right: 2rem;
	}

	td.bold {
		font-weight: bold;
	}

	.italic {
		font-style: italic;
	}
}

.meta-table {
	width: 100%;

	* {
		font-size: 0.9rem;
	}

	th {
		text-align: left;
		padding: 0.25em 0;
		padding-right: 2rem;
	}

	td.bold {
		font-weight: bold;
	}

	.italic {
		font-style: italic;
	}
}

@media print {
	.hide-print {
		display: none !important;
	}

	html, body {
		background-color: white;
	}

	.card {
		box-shadow: none !important;
		padding: 0 !important;
	}

	#root {
		height: unset;
	}
}

@media screen {
	.hide-screen {
		display: none !important;
	}
}

body {
	&.mobile {
		input,
		select {
			font-size: 16px !important;
		}
	}
}

.printable--accounting-summary {
	padding: 1cm;

	p {
		font-size: 12px;
	}
	
	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.logo {
			img {
				width: 6cm;
			}
		}

		.document-info {
			text-align: right;
		}
	}

	.small-title {
		text-transform: uppercase;
		font-size: 10px;
	}

	.summary-text {
		.title {
			font-size: 25.5px;
			font-weight: normal;
		}

		.description {
			font-size: 15px;
			width: 82%;
			line-height: 1.75em;
		}
	}

	.summary-row {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 2rem;

		.column {
			.box {
				border: solid 1px #f4f4f4;
				border-radius: 10px;
				padding: 0.75rem;

				p {
					&:not(:last-child) {
						margin-bottom: 0.3rem;
					}
				}
			}
		}
	}

	.to-be-recived-box {
		border: solid 1px #f4f4f4;
		border-radius: 10px;
		padding: 0.75rem;
		background-color: #f4f4f4;

		.italics {
			font-style: italic;
		}
	}

	.objects-table {
		width: 100%;

		th {
			padding-top: 1rem;
			font-size: 12px;
			text-align: right;
			font-weight: 200;
			border-bottom: 1px solid #f4f4f4;
		}

		tbody {
			tr {
				td {
					font-size: 15px;
					vertical-align: bottom;
					padding-top: 0.7rem;
					padding-bottom: 0.7rem;
					/* border-top: 1px solid #F4F4F4; */
					border-bottom: 1px solid #f4f4f4;

					&.image {
						padding-bottom: 0.4rem;
						img {
							width: 38px;
							height: 38px;
							border-radius: 5px;
						}
					}

					&.title {
						padding-left: 1rem;

						.id {
							font-size: 10px;
							font-weight: normal;
						}

						.title {
							width: 25rem;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							text-transform: capitalize;
						}
					}

					&.ended {
						text-align: right;
					}

					&.bid {
						text-align: right;
					}

					&.exclude-checkbox {
						& > label {
							margin-left: 1rem;
							margin-bottom: 1rem;
						}
					}
				}

				&.excluded {
					opacity: 0.5;
				}
			}
		}
	}

	.footer {
		padding-top: 1rem;

		.meta-row {
			display: flex;
			align-items: center;
			justify-content: center;

			p {
				color: #555555;
				height: 1em;
				line-height: 1em;

				&:not(:last-child) {
					margin-right: 0.5rem;
					padding-right: 0.5rem;
					border-right: solid 1px currentColor;
				}
			}
		}
	}

	@media screen {
		.page-card {
			width: 22cm;
		}

		.mobile & {
			.hide-print {
				width: 100%;
				min-width: 0;

				.row {
					flex-direction: column;
					align-items: stretch !important;

					& > * {
						margin-bottom: 0.5rem;
					}
				}
			}

			.page-card {
				display: none;
			}
		}
	}

	@media print {
		padding: 0cm;

		.footer {
			position: fixed;
			bottom: 1rem;
			width: 100%;
		}
	}
}