@font-face {
	font-family: 'Nunito';
	src: url('Nunito-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: 'Nunito';
	src: url('Nunito-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Nunito';
	src: url('Nunito-SemiBold.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Nunito';
	src: url('Nunito-Bold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Nunito';
	src: url('Nunito-Black.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}
