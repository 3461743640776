@font-face {
	font-family: 'Metropolis';
	src: url('Metropolis-Light.otf') format('opentype');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: 'Metropolis';
	src: url('Metropolis-Regular.otf') format('opentype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Metropolis';
	src: url('Metropolis-Medium.otf') format('opentype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Metropolis';
	src: url('Metropolis-SemiBold.otf') format('opentype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Metropolis';
	src: url('Metropolis-Bold.otf') format('opentype');
	font-weight: 700;
	font-style: normal;
}
